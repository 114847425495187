import { ModalDialog } from 'Framework/Components/ModalDialog/ModalDialog';
import { Stack } from 'Framework/Extensions/Collections';
import { DialogResult, IDialogOptions, IDialogService } from 'Framework/Services/IDialogService';
import { injectable } from 'inversify';
import Vue from 'vue';

@injectable()
export class VDialogService implements IDialogService {
    public async openAsync<TComponent extends ModalDialog>(controller: string, modalComponent: new () => TComponent, dataContext?: any, dialogOptions?: IDialogOptions): Promise<DialogResult> {
        const dialog: Dialog = new Dialog();
        this._dialogsStack.push(dialog);

        return new Promise<DialogResult>(resolve => {
            dialog.resolver = resolve;

            dialogOptions = {
                ...VDialogService.DEFAULT_OPTIONS,
                ...dialogOptions
            };

            dialog.defaultResult = dialogOptions.dialogResult;

            const modalName = (modalComponent as any).extendOptions
                ? (modalComponent as any).extendOptions.name
                : modalComponent.name

            const contentTemplate = () => import(/* webpackMode: 'weak' */ `../../Areas/${controller}/Dialogs/${modalName}.vue`);

            dialog.isDialogOpened = true;

            let $root: Vue = (document.body.firstElementChild as any).__vue__;
            const $this = this;

            let dynamicDialog = Vue.component('DynamicDialog', {
                parent: $root,
                data: function() {
                    return {
                        width: dialogOptions.width,
                        height: dialogOptions.height,
                        vDialogProps: {
                            contentClass: 'override_dialog',
                            persistent: dialogOptions.fullScreen || $root.$vuetify.breakpoint.mobile || dialogOptions.persistent,
                            scrollable: dialogOptions.scrollable,
                            value: true,
                            fullscreen: dialogOptions.fullScreen || $root.$vuetify.breakpoint.mobile
                        }
                    }
                },
                provide: function () {
                    return {
                        dialogOptions: this.$data.vDialogProps
                    }
                },
                render: function(createElement) {
                    return createElement(
                        'v-dialog', {
                        props: this.$data.vDialogProps,
                        on: {
                            'input': (value: boolean) => $this.isDialogOpened = value
                        }
                    },
                        [
                            createElement(contentTemplate, {
                                props: {
                                    dataContext: Vue.observable(dataContext) || null
                                },
                                on: {
                                    'close-dialog': (e: { sender: Vue, args?: DialogResult }) => {
                                        dialog.dialogResult = e.args;
                                        $this.isDialogOpened = false;
                                    },
                                    'maximize-dialog': (e: { sender: Vue }) => {
                                        this.vDialogProps.fullscreen = true;
                                    },
                                    'restore-dialog': (e: { sender: Vue }) => {
                                        this.vDialogProps.fullscreen = false;
                                    }
                                },
                                style: {
                                    height: this.$data.height,
                                    width: this.$data.width
                                }
                            })
                        ]
                    );
                }
            });

            let instance = new dynamicDialog();
            instance.$mount();

            dialog.destroy = () => {
                $root.$children.splice($root.$children.indexOf(instance), 1);
                instance.$destroy();
            };
        })
    }

    public get isDialogOpened(): boolean {
        return this._dialogsStack.currentItem?.isDialogOpened ?? false;
    }

    public set isDialogOpened(value: boolean) {
        const dialog = this._dialogsStack.currentItem;

        if (dialog == null) {
            return;
        }

        dialog.isDialogOpened = value;

        if (!value) {
            this._dialogsStack.pop();
        }
    }

    private readonly _dialogsStack: Stack<Dialog> = new Stack<Dialog>();

    public static readonly DEFAULT_OPTIONS: IDialogOptions = {
        width: 'auto',
        height: 'auto',
        persistent: false,
        dialogResult: DialogResult.None,
        scrollable: true,
        fullScreen: false
    };
}

class Dialog {
    public dialogResult: DialogResult;
    public defaultResult: DialogResult;
    public resolver: (value?: DialogResult | PromiseLike<DialogResult>) => void;
    public destroy: () => void;

    public get isDialogOpened(): boolean {
        return this._isDialogOpened;
    }

    public set isDialogOpened(value: boolean) {
        if (!value) {
            if (this.isDialogOpened && this.resolver) {
                this.resolver(this.dialogResult || this.defaultResult);
            }

            if (this.destroy) {
                this.destroy();
            }
        }

        this._isDialogOpened = value;
    }

    private _isDialogOpened: boolean = false;
}
